import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import AuthService from "../services/auth.service";
import LogoComponent from "./logo";

const navigation = [
	{ name: "Home", href: "home", current: true },
	{ name: "About", href: "about-us", current: false },
	{ name: "Track Parcel", href: "track_parcel", current: false }
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const parcelsHash = { name: "Parcels", href: "parcels", current: false };
const dashboardHash = { name: "Dashboard", href: "dashboard", current: false };

export const Header = () => {
	const currentUser = AuthService.getCurrentUser();

	useEffect(() => {
		if (currentUser) {
			if (!navigation.includes(parcelsHash)) {
				navigation.push(parcelsHash);
			}
		} else {
			if (navigation.includes(parcelsHash)) {
				navigation.push(parcelsHash);
			}
		}
	}, []);

	const logOut = () => {
		AuthService.logout();
		window.location.replace("/");
	};

	return (
		<Disclosure as="nav" className="bg-white shadow-xl">
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
						<div className="relative flex h-16 items-center justify-between">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="absolute -inset-0.5" />
									<span className="sr-only">Open main menu</span>
									{open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
								</Disclosure.Button>
							</div>
							<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex flex-shrink-0 items-center">
									<a href="/">
										<LogoComponent height={80} />
									</a>
								</div>
								<div className="hidden sm:ml-6 sm:block">
									<div className="flex space-x-4">
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													item.current ? "bg-gradient-to-r from-violet-700 to-fuchsia-600 text-white" : "text-black-300 hover:bg-gray-700 hover:text-white",
													"rounded-md px-3 py-2 text-sm font-medium"
												)}
												aria-current={item.current ? "page" : undefined}
											>
												{item.name}
											</a>
										))}
									</div>
								</div>
							</div>

							<div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
								{!currentUser && (
									<div className="">
										<Menu as="div" className="relative ml-3 md:hidden">
											<div>
												<Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
													<span className="absolute -inset-1.5" />
													<span className="sr-only">Open user menu</span>
													<img className="h-8 w-8 rounded-full" src={"//ssl.gstatic.com/accounts/ui/avatar_2x.png"} alt="" />
												</Menu.Button>
											</div>
											<Transition
												as={Fragment}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-[#988FFF] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
													<Menu.Item>
														{({ active }) => (
															// <a href="/login" class="bg-transparent hover:bg-gray-700 hover:text-white text-black py-2 px-4 rounded">
															<a href="/login" className={classNames(active ? "bg-gray-100 text-purple-600" : "", "text-white block px-4 py-2 text-sm hover:text-black")}>
																Login
															</a>
														)}
													</Menu.Item>

													<Menu.Item>
														{({ active }) => (
															// <a href="/signup" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4">

															<a href="/signup" className={classNames(active ? "bg-gray-100 text-purple-600" : "", "text-white block px-4 py-2 text-sm hover:text-black")}>
																Signup
															</a>
														)}
													</Menu.Item>
												</Menu.Items>
											</Transition>
										</Menu>
										<div className="relative hidden md:flex  ml-3  flex-row gap-4">
											<a href="/login" class="bg-transparent hover:bg-gray-700 hover:text-white text-black py-2 px-4 rounded">
												Login
											</a>

											<a href="/signup" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4">
												Signup
											</a>
										</div>
									</div>
								)}

								{/* Profile dropdown */}
								{currentUser && (
									<Menu as="div" className="relative ml-3">
										<div>
											<Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
												<span className="absolute -inset-1.5" />
												<span className="sr-only">Open user menu</span>
												<img className="h-8 w-8 rounded-full" src={currentUser.user.profile_pic_url || "//ssl.gstatic.com/accounts/ui/avatar_2x.png"} alt="" />
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-[#988FFF] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
												<Menu.Item>
													{({ active }) => (
														<a href="/profile" className={classNames(active ? "bg-gray-100 text-purple-600" : "", "text-white block px-4 py-2 text-sm hover:text-black")}>
															Your Profile
														</a>
													)}
												</Menu.Item>
												<Menu.Item>
													{({ active }) => (
														<a href="/dashboard" className={classNames(active ? "bg-gray-100 text-purple-600" : "", "text-white block px-4 py-2 text-sm hover:text-black")}>
															Dashboard
														</a>
													)}
												</Menu.Item>
												<Menu.Item>
													{({ active }) => (
														<a href="#" onClick={logOut} className={classNames(active ? "bg-gray-100 text-purple-600" : "", "text-white block px-4 py-2 text-sm hover:text-black")}>
															Sign out
														</a>
													)}
												</Menu.Item>
											</Menu.Items>
										</Transition>
									</Menu>
								)}
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="space-y-1 px-2 pb-3 pt-2">
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as="a"
									href={item.href}
									className={classNames(item.current ? "bg-gray-900 text-white" : "text-gray-500 hover:bg-gray-700 hover:text-white", "block rounded-md px-3 py-2 text-base font-medium")}
									aria-current={item.current ? "page" : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};
