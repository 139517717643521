// import { CiFloppyDisk, CiSaveDown1 } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import FormAction from "../../components/FormAction";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { signupFields } from "../../constants/formFields";
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Initialize Firebase (make sure to replace with your Firebase config)
const firebaseConfig = {
	apiKey: "AIzaSyCA8lStBJcswiLF8KIaN2iPhx-TUJ6YS7U",
	authDomain: "liftsearch-31ac1.firebaseapp.com",
	databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
	storageBucket: "gs://liftsearch-31ac1.appspot.com/"
};

const app = initializeApp(firebaseConfig);

const fields = signupFields;
let fieldsState = {currentUser: {}};

fields.forEach((field) => (fieldsState[field.id] = ""));

export function ProfilePage() {
	const [signupState, setSignupState] = useState(fieldsState);
	const [file, setFile] = useState(null);
	const [uploadProgress, setUploadProgress] = useState(0);

	const { currentUser } = signupState

	const handleChange = (e) => {
		const selectedFile = e.target.files[0];
		setFile(selectedFile);
	};
	
	const handleSubmit = (e) => {
		e.preventDefault();
		createAccount();
	};

	const handleUpload = async () => {
		if (!file) {
			alert('Please select a file');
			return;
		}

		const storage = getStorage();
		const storageRef = ref(storage, `uploads/${file.name}`);

		// Upload the file
		const uploadTask = uploadBytes(storageRef, file);

		try {
			// Wait for the upload to complete
			await uploadTask;

			// Get the download URL
			const downloadUrl = await getDownloadURL(storageRef);
			UserService.update(currentUser.id, {
				profile_pic_url: downloadUrl
			  }).then(() => {
				console('Login again to reflect changes');
			  })		
			} catch (error) {
			console.error('Error uploading file:', error.message);
		}
	};

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const data = await AuthService.getCurrentUser();
				setSignupState((prevState) => ({
					...prevState,
					currentUser: data?.user,
				}));
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		};

		fetchUser()
	}, [])

	//Handle Login API Integration here
	const createAccount = () => {
		let signupFields = {
			email: signupState["email-address"],
			cellphone: signupState["cellphone"],
			first_name: signupState["first_name"],
			last_name: signupState["last_name"],
			password: signupState["password"]
		};

		AuthService.register(signupFields.cellphone, signupFields.first_name, signupFields.last_name, signupFields.email, signupFields.password).then(
			() => {
				console.log('updated')
			},
			(error) => {
				console.log(error);
			}
		);
	};

	return (
		<div className="w-full flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200 ">
			<div className="flex items-center flex-col py-10">
				<p className="text-purple-800 text-3xl md:text-4xl font-semibold ">Edit Profile</p>
			</div>

			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 py-10">
				<div className="flex items-center md:items-end flex-col">
					<div className="flex flex-col gap-4 w-full md:w-2/4 px-4">
						<div className="flex flex-col">
							<p className="text-purple-600 font-semibold text-sm">First Name</p>
							<input
								type="text"
								value={currentUser?.first_name}
								placeholder="First Name"
								className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col">
							<p className="text-purple-600 font-semibold text-sm">Last Name</p>
							<input
								type="text"
								value={currentUser?.last_name}
								placeholder="Last Name"
								className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col">
							<p className="text-purple-600 font-semibold text-sm">Email</p>

							<input
								type="email"
								value={currentUser?.email}
								placeholder="Email"
								className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col">
							<p className="text-purple-600 font-semibold text-sm">Phone Number</p>
							<input
								type="text"
								value={currentUser?.cellphone}
								placeholder="Phone Number"
								className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col">
							<p className="text-purple-600 font-semibold text-sm">Gender</p>
							<select
								id="gender"
								value="Male"
								className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
							>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
							</select>
						</div>
					</div>
				</div>

				<div className="flex items-center md:items-start flex-col pt-4">
					<div className="flex flex-col items-center w-3/4  px-4	gap-4	">
						<img className="max-h-96 rounded-3xl" src={currentUser?.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png'} alt="Profile" ></img>
						<input required className="text-purple-600 font-semibold" type="file" onChange={handleChange} />
						<button className="text-purple-600 font-semibold" onClick={handleUpload}>Change</button>
					</div>
				</div>
			</div>
			{/* <div className="flex items-center flex-col pb-10">
				<FormAction handleSubmit="" text="Update" icon={<CiFloppyDisk size={20} />} />
			</div> */}
		</div>
	);
};
