export default function LogoComponent({ height }) {
	return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="80pt" viewBox="0 0 798.000000 385.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,385.000000) scale(0.100000,-0.100000)"
fill="#6b21a8" stroke="none">
<path d="M1016 3829 l-26 -20 0 -810 0 -809 253 0 c245 0 255 -1 273 -21 17
-19 19 -41 22 -244 3 -215 2 -225 -17 -244 -20 -20 -32 -21 -242 -21 -223 0
-249 -4 -276 -39 -9 -12 -12 -82 -13 -249 l0 -233 25 -24 c24 -25 24 -25 240
-25 229 0 272 7 294 48 7 13 11 100 11 232 0 119 4 219 10 230 21 39 49 40
831 40 416 0 765 3 774 6 46 18 45 -3 45 1109 l0 1046 -25 24 -24 25 -1064 0
c-1062 0 -1064 0 -1091 -21z m1638 -545 c14 -6 16 -63 16 -545 l0 -539 -547 2
-548 3 -3 530 c-1 292 0 536 3 543 3 9 119 12 534 12 291 0 536 -3 545 -6z"/>
<path d="M1882 2748 l3 -223 228 -3 227 -2 0 225 0 225 -230 0 -230 0 2 -222z"/>
<path d="M3558 865 c-108 -61 -21 -220 93 -172 47 19 64 43 64 88 0 74 -90
122 -157 84z"/>
<path d="M50 421 l0 -401 110 0 110 0 0 119 0 119 113 4 c97 3 119 7 162 30
97 51 144 128 145 239 0 124 -48 207 -148 256 -56 28 -59 28 -274 31 l-218 4
0 -401z m355 215 c35 -15 65 -59 65 -96 0 -35 -29 -86 -56 -99 -14 -6 -52 -11
-84 -11 l-60 0 0 110 0 110 50 0 c28 0 66 -6 85 -14z"/>
<path d="M3180 420 l0 -400 105 0 105 0 0 400 0 400 -105 0 -105 0 0 -400z"/>
<path d="M945 620 c-22 -5 -52 -13 -66 -18 -40 -16 -100 -73 -115 -109 -26
-62 -24 -63 86 -63 81 0 100 3 100 15 0 33 86 48 114 19 19 -19 21 -48 3 -63
-8 -6 -58 -17 -113 -25 -114 -15 -176 -42 -204 -89 -26 -42 -34 -104 -21 -153
34 -126 230 -167 329 -68 19 19 36 34 38 34 2 0 4 -18 4 -40 l0 -41 98 3 97 3
3 203 c1 120 -2 219 -8 242 -21 79 -87 129 -195 149 -66 12 -92 12 -150 1z
m135 -380 c0 -56 -34 -90 -89 -90 -32 0 -44 5 -57 25 -15 23 -15 27 0 50 9 14
29 27 44 31 15 3 41 10 57 14 17 5 33 9 38 9 4 1 7 -17 7 -39z"/>
<path d="M1701 614 c-38 -16 -55 -34 -76 -79 -14 -30 -14 -30 -15 28 l0 57
-105 0 -105 0 0 -300 0 -300 105 0 105 0 0 160 c0 171 7 207 50 245 18 16 35
20 86 18 l64 -1 0 84 c0 81 -1 85 -26 94 -33 13 -42 12 -83 -6z"/>
<path d="M2053 620 c-130 -27 -215 -126 -229 -268 -22 -208 123 -355 335 -340
124 9 217 74 246 171 26 89 29 87 -84 87 l-99 0 -7 -30 c-8 -34 -48 -70 -79
-70 -55 0 -96 64 -96 150 0 86 41 150 96 150 31 0 71 -36 79 -70 l7 -30 100 0
101 0 -7 41 c-14 85 -66 152 -146 188 -53 23 -157 34 -217 21z"/>
<path d="M2703 615 c-138 -38 -213 -145 -213 -300 0 -126 58 -227 158 -277 40
-19 63 -23 147 -23 83 0 108 4 150 23 75 35 118 81 139 150 7 22 6 22 -91 22
-86 0 -101 -3 -115 -20 -52 -60 -178 -20 -178 56 l0 24 196 0 197 0 -6 74 c-9
125 -58 206 -150 250 -67 33 -163 41 -234 21z m165 -171 c41 -47 32 -54 -73
-54 -61 0 -95 4 -95 11 0 42 63 83 115 74 17 -3 40 -16 53 -31z"/>
<path d="M4155 615 c-23 -8 -58 -33 -82 -60 l-43 -47 0 56 0 56 -100 0 -100 0
0 -300 0 -300 104 0 104 0 4 189 c3 185 4 190 27 215 30 32 87 35 122 7 24
-19 24 -23 27 -215 l3 -196 105 0 104 0 0 223 c0 218 -1 224 -25 273 -47 94
-149 134 -250 99z"/>
<path d="M3502 323 l3 -298 108 -3 107 -3 0 301 0 300 -110 0 -110 0 2 -297z"/>
<path d="M5657 454 c-15 -15 -7 -45 11 -42 9 2 17 12 17 22 0 20 -16 31 -28
20z"/>
<path d="M5790 440 c-11 -11 -20 -33 -20 -50 0 -20 -5 -30 -15 -30 -8 0 -15
-4 -15 -10 0 -5 7 -10 15 -10 12 0 15 -19 15 -110 0 -90 3 -110 15 -110 11 0
15 21 17 108 l3 107 28 3 c34 4 36 22 2 22 -14 0 -28 6 -31 14 -7 19 13 56 31
56 8 0 15 7 15 15 0 22 -36 19 -60 -5z"/>
<path d="M7330 285 c0 -158 1 -165 20 -165 19 0 20 7 20 89 0 68 4 93 16 105
33 33 95 32 113 -3 6 -10 11 -57 12 -104 2 -78 4 -87 20 -87 17 0 19 9 19 83
0 99 -19 147 -65 159 -36 10 -73 2 -97 -19 -17 -15 -18 -13 -18 45 0 55 -2 62
-20 62 -19 0 -20 -7 -20 -165z"/>
<path d="M4810 274 l0 -157 80 5 c66 4 84 9 105 28 34 32 33 74 -4 111 -16 16
-26 29 -21 29 19 0 40 34 40 65 0 54 -36 75 -126 75 l-74 0 0 -156z m154 110
c36 -35 8 -83 -50 -85 -19 -1 -42 -2 -51 -3 -15 -1 -19 9 -21 52 l-3 52 55 0
c36 0 60 -5 70 -16z m1 -139 c30 -29 31 -49 5 -75 -15 -15 -33 -20 -75 -20
l-55 0 0 60 0 60 50 0 c41 0 56 -5 75 -25z"/>
<path d="M5460 275 l0 -155 70 0 c56 0 70 3 70 15 0 12 -13 15 -55 15 l-55 0
0 140 c0 118 -2 140 -15 140 -13 0 -15 -24 -15 -155z"/>
<path d="M5920 395 c0 -24 -5 -35 -15 -35 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 12 0 15 -17 15 -94 0 -104 5 -113 64 -123 29 -4 36 -2 36 11 0 10 -8 16
-24 16 -38 0 -47 21 -44 107 l3 78 33 3 c17 2 32 7 32 12 0 5 -15 10 -32 12
-29 3 -33 7 -36 36 -5 46 -32 43 -32 -3z"/>
<path d="M6120 363 c-30 -12 -50 -37 -50 -63 0 -27 26 -60 48 -60 6 0 30 -7
52 -16 41 -17 51 -39 28 -62 -20 -20 -76 -15 -90 8 -13 20 -48 28 -48 11 0
-27 39 -54 86 -58 60 -6 97 17 97 62 0 30 -33 65 -60 65 -30 0 -83 32 -83 51
0 38 65 52 95 19 21 -23 45 -26 45 -7 0 34 -79 67 -120 50z"/>
<path d="M6363 355 c-76 -33 -91 -139 -28 -202 33 -33 39 -35 86 -31 47 5 99
36 99 60 0 16 -37 8 -55 -12 -26 -29 -79 -26 -110 5 -47 47 -36 55 75 55 94 0
100 1 100 20 0 44 -29 90 -64 105 -43 18 -62 18 -103 0z m101 -36 c14 -11 26
-29 26 -40 0 -17 -8 -19 -80 -19 -89 0 -98 8 -55 54 30 32 72 34 109 5z"/>
<path d="M6644 360 c-58 -23 -85 -102 -58 -168 27 -69 97 -92 162 -52 32 20
32 20 32 0 0 -13 7 -20 20 -20 19 0 20 7 20 120 0 113 -1 120 -20 120 -11 0
-20 -7 -20 -15 0 -12 -6 -12 -37 5 -40 21 -65 24 -99 10z m107 -49 c22 -23 29
-39 29 -69 0 -110 -149 -127 -167 -19 -15 96 73 153 138 88z"/>
<path d="M6956 349 c-21 -17 -26 -18 -26 -5 0 9 -8 16 -20 16 -19 0 -20 -7
-20 -120 0 -113 1 -120 20 -120 18 0 20 7 20 88 0 96 12 122 58 122 15 0 22 6
22 20 0 26 -20 25 -54 -1z"/>
<path d="M7114 356 c-46 -20 -64 -52 -64 -114 0 -48 4 -58 35 -89 33 -33 38
-35 87 -31 40 4 57 11 75 32 13 15 23 31 23 37 0 17 -26 9 -50 -16 -45 -48
-124 -25 -136 40 -18 99 68 164 132 100 22 -23 54 -34 54 -19 0 19 -26 48 -55
60 -42 17 -61 17 -101 0z"/>
<path d="M5063 347 c3 -7 24 -60 48 -117 46 -113 46 -106 4 -203 -5 -13 -2
-17 11 -17 14 0 27 22 53 88 19 48 52 126 72 175 34 78 36 87 19 87 -14 0 -27
-23 -56 -96 -20 -52 -40 -92 -44 -87 -4 4 -22 47 -41 96 -25 68 -37 87 -52 87
-12 0 -17 -5 -14 -13z"/>
<path d="M5650 240 c0 -111 1 -120 19 -120 17 0 19 10 20 120 2 115 1 120 -19
120 -19 0 -20 -6 -20 -120z"/>
</g>
</svg>

	);
}
